import { createStore } from "vuex";
import { fetchAccessRights } from "../api.js";

export default createStore({
  state: {
    userName: "",
    token: "",
    workInLocalNetwork: true,
    workInTestBase: true,
    accessGofroline: false,
    accessCut1: false,
    accessCut2: false,
    accessCut3: false,
    accessSklad: false,
    //tasksCount: 0,
  },

  getters: {
    isAuthorized(state) {
      return state.userName !== "";
    },

    visibleGroup(state) {
      return ["Тарасов Дмитрий", "Шапин Олег", "Дизайнер"].includes(state.userName);
    },

    visibleGline(state) {
      return ["Тарасов Дмитрий", "Мастер", "Шапин Олег", "Дизайнер"].includes(state.userName);
    },

    visibleCut1(state) {
      return ["Тарасов Дмитрий", "Минилайн", "Шапин Олег", "Дизайнер"].includes(state.userName);
    },

    visibleCut2(state) {
      return ["Тарасов Дмитрий", "Ротор1600", "Шапин Олег", "Дизайнер"].includes(state.userName);
    },

    visibleCut3(state) {
      return ["Тарасов Дмитрий", "Ротор2400", "Шапин Олег", "Дизайнер"].includes(state.userName);
    },

    visibleTehkartaCatalog(state) {
      return ["Тарасов Дмитрий", "Мастер", "Минилайн", "Ротор1600", "Ротор2400", "Шапин Олег", "Дизайнер"].includes(
        state.userName
      );
    },

    visibleSetLimit(state) {
      return ["Тарасов Дмитрий", "Колесникова Снежана"].includes(state.userName);
    },

    visibleSetPrice(state) {
      return ["Тарасов Дмитрий", "Колесникова Снежана"].includes(state.userName);
    },

    visibleFinance(state) {
      return ["Тарасов Дмитрий", "Колесникова Снежана"].includes(state.userName);
    },

    visibleDelivery(state) {
      return ["Тарасов Дмитрий", "Колесникова Снежана"].includes(state.userName);
    },

    visibleSklad(state) {
      return ["Тарасов Дмитрий", "Палий Галина", "Шапин Олег", "Склад"].includes(state.userName);
    },
  },

  mutations: {
    setUserName(state, userName) {
      state.userName = userName;
    },

    setToken(state, token) {
      state.token = token;
    },

    setWorkInLocalNetwork(state, workInLocalNetwork) {
      state.workInLocalNetwork = workInLocalNetwork;
    },

    setWorkInTestBase(state, workInTestBase) {
      state.workInTestBase = workInTestBase;
    },

    setAccessGofroline(state, value) {
      state.accessGofroline = value;
    },

    setAccessCut1(state, value) {
      state.accessCut1 = value;
    },

    setAccessCut2(state, value) {
      state.accessCut2 = value;
    },

    setAccessCut3(state, value) {
      state.accessCut3 = value;
    },

    setAccessSklad(state, value) {
      state.accessSklad = value;
    },
  },

  actions: {
    login({ commit, state, dispatch }, { userName, password }) {
      var Base64 = {
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
        encode: function (e) {
          var t = "";
          var n, r, i, s, o, u, a;
          var f = 0;
          e = Base64._utf8_encode(e);
          while (f < e.length) {
            n = e.charCodeAt(f++);
            r = e.charCodeAt(f++);
            i = e.charCodeAt(f++);
            s = n >> 2;
            o = ((n & 3) << 4) | (r >> 4);
            u = ((r & 15) << 2) | (i >> 6);
            a = i & 63;
            if (isNaN(r)) {
              u = a = 64;
            } else if (isNaN(i)) {
              a = 64;
            }
            t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
          }
          return t;
        },
        decode: function (e) {
          var t = "";
          var n, r, i;
          var s, o, u, a;
          var f = 0;
          e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
          while (f < e.length) {
            s = this._keyStr.indexOf(e.charAt(f++));
            o = this._keyStr.indexOf(e.charAt(f++));
            u = this._keyStr.indexOf(e.charAt(f++));
            a = this._keyStr.indexOf(e.charAt(f++));
            n = (s << 2) | (o >> 4);
            r = ((o & 15) << 4) | (u >> 2);
            i = ((u & 3) << 6) | a;
            t = t + String.fromCharCode(n);
            if (u != 64) {
              t = t + String.fromCharCode(r);
            }
            if (a != 64) {
              t = t + String.fromCharCode(i);
            }
          }
          t = Base64._utf8_decode(t);
          return t;
        },
        _utf8_encode: function (e) {
          e = e.replace(/\r\n/g, "\n");
          var t = "";
          for (var n = 0; n < e.length; n++) {
            var r = e.charCodeAt(n);
            if (r < 128) {
              t += String.fromCharCode(r);
            } else if (r > 127 && r < 2048) {
              t += String.fromCharCode((r >> 6) | 192);
              t += String.fromCharCode((r & 63) | 128);
            } else {
              t += String.fromCharCode((r >> 12) | 224);
              t += String.fromCharCode(((r >> 6) & 63) | 128);
              t += String.fromCharCode((r & 63) | 128);
            }
          }
          return t;
        },
        _utf8_decode: function (e) {
          var t = "";
          var n = 0;
          var r = (c1 = c2 = 0);
          while (n < e.length) {
            r = e.charCodeAt(n);
            if (r < 128) {
              t += String.fromCharCode(r);
              n++;
            } else if (r > 191 && r < 224) {
              c2 = e.charCodeAt(n + 1);
              t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
              n += 2;
            } else {
              c2 = e.charCodeAt(n + 1);
              c3 = e.charCodeAt(n + 2);
              t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
              n += 3;
            }
          }
          return t;
        },
      };

      commit("setUserName", userName);
      commit("setToken", Base64.encode(state.userName + ":" + password));

      if (userName !== "Тарасов Дмитрий") {
        commit("setWorkInTestBase", false);
      }

      dispatch("saveToLocalSorage");
    },

    logout({ state, commit, dispatch }) {
      commit("setUserName", "");
      commit("setToken", "");

      if (state.userName !== "Тарасов Дмитрий") {
        commit("setWorkInTestBase", false);
      }

      dispatch("saveToLocalSorage");
    },

    setWorkInLocalNetwork({ commit, dispatch }, workInLocalNetwork) {
      commit("setWorkInLocalNetwork", workInLocalNetwork);
      dispatch("saveToLocalSorage");
    },

    setWorkInTestBase({ commit, dispatch }, workInTestBase) {
      commit("setWorkInTestBase", workInTestBase);
      dispatch("saveToLocalSorage");
    },

    initStore({ state, commit }) {
      if (localStorage.userName) {
        commit("setUserName", localStorage.userName);
      }

      if (localStorage.token) {
        commit("setToken", localStorage.token);
      }

      if (localStorage.workInLocalNetwork) {
        commit("setWorkInLocalNetwork", JSON.parse(localStorage.workInLocalNetwork));
      }

      if (state.userName !== "Тарасов Дмитрий") {
        commit("setWorkInTestBase", false);
      } else if (localStorage.workInTestBase) {
        commit("setWorkInTestBase", JSON.parse(localStorage.workInTestBase));
      }

      commit("setAccessGofroline", false);
      commit("setAccessCut1", false);
      commit("setAccessCut2", false);
      commit("setAccessCut3", false);
      commit("setAccessSklad", false);
      //commit('tasksCount', 0);
    },

    async readAccess() {
      const fetchResult = await fetchAccessRights();

      this.state.accessGofroline = fetchResult.data.rights.accessGofroline;
      this.state.accessCut1 = fetchResult.data.rights.accessCut1;
      this.state.accessCut2 = fetchResult.data.rights.accessCut2;
      this.state.accessCut3 = fetchResult.data.rights.accessCut3;
      this.state.accessSklad = fetchResult.data.rights.accessSklad;

      var count = 0;
      count += fetchResult.data.rights.accessGofroline == true ? 1 : 0;
      count += fetchResult.data.rights.accessCut1 == true ? 1 : 0;
      count += fetchResult.data.rights.accessCut2 = true ? 1 : 0;
      count += fetchResult.data.rights.accessCut3 == true ? 1 : 0;

      //this.state.tasksCount = count;
    },

    saveToLocalSorage({ state }) {
      localStorage.userName = state.userName;
      localStorage.token = state.token;
      localStorage.workInLocalNetwork = state.workInLocalNetwork;
      localStorage.workInTestBase = state.workInTestBase;
    },
  },
});
