<template>
  <nav
    class="navbar navbar-expand-md bg-body-tertiary"
    style="background-color: #e3f2fd"
  >
    <div class="container-fluid">
      <button class="backbutton" type="button" @click="$router.go(-1)">
        <img
          src="../assets/back32.png"
          alt="Вернуться назад"
          width="28"
          height="28"
        />
      </button>

      <img
        src="../assets/favicon-32x32.png"
        alt="Logo"
        width="30"
        height="30"
        class="d-inline-block align-text-top"
      />

      <a class="navbar-brand" href="#">&nbsp;"Гофролиния"</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <template v-if="!$store.getters.visibleGroup">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleGline">
              <router-link
                class="dropdown-item menu-item"
                to="/tasksglinepage"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Задания ЛГК
                </a>
              </router-link>
            </li>

            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleCut1">
              <router-link
                class="dropdown-item menu-item"
                :to="{ name: 'taskscutpage', params: { cutn: 1 } }"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Задания Минилайн
                </a></router-link
              >
            </li>

            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleCut2">
              <router-link
                class="dropdown-item menu-item"
                :to="{ name: 'taskscutpage', params: { cutn: 2 } }"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Задания Ротор-1600
                </a>
              </router-link>
            </li>

            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleCut3">
              <router-link
                class="dropdown-item menu-item"
                :to="{ name: 'taskscutpage', params: { cutn: 3 } }"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Задания Ротор-2400
                </a>
              </router-link>
            </li>

            <li
              class="nav-item nav-item_mg"
              v-if="$store.getters.visibleTehkartaCatalog"
            >
              <router-link
                class="dropdown-item menu-item"
                to="/tehkartacatalogpage"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Каталог техкарт</a
                ></router-link
              >
            </li>

            <li
              class="nav-item nav-item_mg"
              v-if="$store.getters.visibleSetLimit"
            >
              <router-link
                class="dropdown-item menu-item"
                to="/setlimit"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Лимит
                </a></router-link
              >
            </li>

            <li
              class="nav-item nav-item_mg"
              v-if="$store.getters.visibleSetPrice"
            >
              <router-link
                class="dropdown-item menu-item"
                to="/specslist"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                >
                  Цены</a
                ></router-link
              >
            </li>

            <li
              class="nav-item nav-item_mg"
              v-if="$store.getters.visibleDelivery"
            >
              <router-link
                class="dropdown-item menu-item"
                to="/deliverylist"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                  >Доставка</a
                >
              </router-link>
            </li>

            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleSklad">
              <router-link
                class="dropdown-item menu-item"
                to="/sklad"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                  >Остатки по складу</a
                >
              </router-link>
            </li>

            <li class="nav-item nav-item_mg" v-if="$store.getters.visibleSklad">
              <router-link
                class="dropdown-item menu-item"
                to="/skladinv"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  class="dropdown-item menu-item"
                  :href="href"
                  @click="wrapNavigate(navigate, $event)"
                  >Инвентаризация</a
                >
              </router-link>
            </li>
          </ul>
        </template>

        <template v-else>
          <ul style="display: flex; list-style-type: none; margin-bottom: 0px">
            <li class="nav-item dropdown submenu">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Производство
              </a>

              <ul class="dropdown-menu">
                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleGline"
                >
                  <router-link
                    to="/tasksglinepage"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Задания ЛГК</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleCut1"
                >
                  <router-link
                    :to="{ name: 'taskscutpage', params: { cutn: 1 } }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Задания Минилайн</a
                    >
                  </router-link>

                  <!-- <router-link
                    class="dropdown-item menu-item"
                    :to="{ name: 'taskscutpage', params: { cutn: 1 } }"
                    >Задания Минилайн</router-link
                  > -->
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleCut2"
                >
                  <router-link
                    :to="{ name: 'taskscutpage', params: { cutn: 2 } }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Задания Ротор-1600</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleCut3"
                >
                  <router-link
                    :to="{ name: 'taskscutpage', params: { cutn: 3 } }"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Задания Ротор-2400</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleTehkartaCatalog"
                >
                  <router-link
                    to="/tehkartacatalogpage"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Каталог техкарт</a
                    >
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              v-if="$store.getters.visibleFinance"
              class="nav-item dropdown submenu"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Финансы
              </a>

              <ul class="dropdown-menu">
                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleSetLimit"
                >
                  <router-link
                    to="/setlimit"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Лимит</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleSetPrice"
                >
                  <router-link
                    to="/specslist"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Цены</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleDelivery"
                >
                  <router-link
                    to="/deliverylist"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Доставка</a
                    >
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              v-if="$store.getters.visibleSklad"
              class="nav-item dropdown submenu"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Склад
              </a>

              <ul class="dropdown-menu">
                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleSklad"
                >
                  <router-link to="/sklad" custom v-slot="{ href, navigate }">
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Остатки по складу</a
                    >
                  </router-link>
                </li>

                <li
                  class="nav-item nav-item_mg"
                  v-if="$store.getters.visibleSklad"
                >
                  <router-link
                    to="/skladinv"
                    custom
                    v-slot="{ href, navigate }"
                  >
                    <a
                      class="dropdown-item menu-item"
                      :href="href"
                      @click="wrapNavigate(navigate, $event)"
                      >Инвентаризация</a
                    >
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </template>

        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#modalPreference"
            >
              <img src="../assets/preference24.png" />
            </a>
          </li>

          <li v-if="$store.getters.isAuthorized" class="nav-item">
            <router-link class="nav-link" to="/login"
              ><img src="../assets/user24.png" />&nbsp;<strong>{{
                $store.state.userName
              }}</strong></router-link
            >
          </li>

          <li v-if="!$store.getters.isAuthorized" class="nav-item">
            <router-link class="nav-link" to="/login"
              ><img src="../assets/user24.png" />&nbsp;<strong
                >Вход</strong
              ></router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- modal window with preferences -->
  <div
    class="modal fade"
    id="modalPreference"
    tabindex="-1"
    aria-labelledby="modalPreferenceLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Настройки</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-check">
            <label class="form-check-label" for="cbWorkInLocalNetwork">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="workInLocalNetwork"
                id="cbWorkInLocalNetwork"
              />
              Работать в локальной сети (выше скорость)
            </label>
          </div>

          <div class="form-check">
            <label class="form-check-label" for="cbWorkInTestBase">
              <input
                :disabled="$store.state.userName != 'Тарасов Дмитрий'"
                class="form-check-input"
                type="checkbox"
                v-model="workInTestBase"
                id="cbWorkInTestBase"
              />

              <!-- <input                
                class="form-check-input"
                type="checkbox"
                v-model="workInTestBase"
                id="cbWorkInTestBase"
              /> -->
              Работать в тестовой базе (для программиста)
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workInLocalNetwork: true,
      workInTestBase: true,
    };
  },

  methods: {
    workInLocalNetworkWatcher(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("setWorkInLocalNetwork", newValue);
      }
    },

    workInTestBaseWatcher(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("setWorkInTestBase", newValue);
      }
    },
    wrapNavigate(navigate, event) {
      document
        .getElementById("navbarSupportedContent")
        .classList.remove("show");
      navigate(event);
    },
  },

  mounted() {
    this.workInLocalNetwork = this.$store.state.workInLocalNetwork;
    this.$watch("workInLocalNetwork", this.workInLocalNetworkWatcher);

    this.workInTestBase = this.$store.state.workInTestBase;
    this.$watch("workInTestBase", this.workInTestBaseWatcher);
  },
};
</script>

<style scoped>
.menu-item {
  border: 2px solid #707070;
  border-radius: 7px;
  padding: 5px;
  margin: 2px;
}

.nav-item_mg {
  margin: 0px 2px;
}

.submenu {
  border: 2px solid #707070;
  border-radius: 7px;
  padding: 5px;
  margin: 2px;
}

.backbutton {
  border: none;
  /* margin: 0 10px 0 0; */
  padding: 0px;
  background-color: transparent;
}
</style>