<template>
  <div class="container-fluid">
    <div class="p-1 bd-highlight mt-1">
      <select
        class="form-select"
        aria-label="Склад"
        @change="loadCounts"
        v-model="filter.skladCode"
      >
        <option selected value="000000004">Хозяйственный склад К.Маркса</option>
        <option value="000000001">Склад сырья К.Маркса</option>
      </select>
    </div>
    <div class="d-flex justify-content-center flex-row bd-highlight my-1">
      <div class="p-1 bd-highlight">
        <input
          :value="filter.name"
          @input="filter.name = $event.target.value"
          type="text"
          class="form-control"
        />
      </div>

      <button class="btn btn-success m2 mar5" @click="loadCounts">Найти</button>
      <button
        style="margin-left: 5px"
        class="btn btn-success m2 mar5"
        @click="clearFilter"
        :disabled="filter.name == ''"
      >
        X
      </button>
    </div>

    <sklad-count
      v-if="!loadingInProgress && errorText == ''"
      :counts="counts"
    ></sklad-count>
    <div v-if="errorText == '' && loadingInProgress">Загрузка остатков...</div>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import SkladCount from "../components/SkladCount.vue";
import { fetchSkladCounts } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: {
    SkladCount,
  },

  data() {
    return {
      counts: [],
      errorText: "",
      loadingInProgress: false,
      filter: {
        name: "",
        skladCode: "000000004",
      },
    };
  },

  watch: {},

  methods: {
    clearFilter() {
      this.filter.name = "";
      this.loadCounts();
    },

    async loadCounts() {
      this.loadingInProgress = true;

      const fetchResult = await fetchSkladCounts(
        this.filter.skladCode,
        this.filter.name
      );

      this.counts = fetchResult.data;
      this.errorText = fetchResult.errorText;

      for (let i = 0; i < this.counts.length; i++) {
        this.counts[i].index = i + 1;
      }

      this.loadingInProgress = false;
    },
  },

  mounted() {
    this.loadCounts();
  },
};
</script>
  