<template>
  <div class="container-fluid">
    <div class="d-flex flex-row p-1 bd-highlight mt-1">
      <select
        class="form-select"
        aria-label="Склад"
        @change="loadCounts"
        v-model="filter.skladCode"
      >
        <option selected value="000000004">Хозяйственный склад К.Маркса</option>
        <option value="000000001">Склад сырья К.Маркса</option>
      </select>

      <select
        class="form-select mx-1"
        aria-label="Сверка"
        @change="loadCounts"
        v-model="filter.complete"
      >
        <option selected value="0">Несверенные</option>
        <option value="1">Уже сверенные</option>
        <option value="-1">Все</option>
      </select>
    </div>
    <div class="d-flex justify-content-center flex-row bd-highlight my-1">
      <div class="p-1 bd-highlight">
        <input
          :value="filter.name"
          @input="filter.name = $event.target.value"
          type="text"
          class="form-control"
        />
      </div>

      <button class="btn btn-success m2 mar5" @click="loadCounts">Найти</button>
      <button
        style="margin-left: 5px"
        class="btn btn-success m2 mar5"
        @click="clearFilter"
        :disabled="filter.name == ''"
      >
        X
      </button>
    </div>

    <div v-if="!loadingInProgress && errorText == ''">
      <table class="table table-bordered">
        <thead>
          <tr>
            <td><strong>Наименование</strong></td>
            <td><strong>Группа</strong></td>
            <td><strong>Остаток (упп)</strong></td>
            <td></td>
          </tr>
        </thead>

        <template v-for="currentCount in counts" :key="currentCount.index">
          <tr>
            <td>{{ currentCount.name }}</td>
            <td>{{ currentCount.group }}</td>
            <td v-if="currentCount.count_calc === currentCount.count_fact">
              {{ currentCount.count_calc }}
            </td>
            <td v-if="currentCount.count_calc !== currentCount.count_fact">
              <s>{{ currentCount.count_calc }}</s> {{ currentCount.count_fact }}
            </td>

            <td>
              <a
                v-bind:class="
                  currentCount.complete ? 'class_red' : 'class_green'
                "
                @click="
                  currentCount.complete
                    ? returnToUnverified(currentCount)
                    : openVerifyModal(currentCount)
                "
                >{{ currentCount.complete ? "Вернуть" : "Сверить" }}</a
              >
            </td>
          </tr>
        </template>
      </table>
    </div>

    <div v-if="errorText == '' && loadingInProgress">Загрузка данных...</div>
    <div v-if="errorText != ''">{{ errorText }}</div>

    <!-- Модальное окно для сверки -->
    <div
      class="modal fade"
      id="verifyModal"
      tabindex="-1"
      aria-labelledby="verifyModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="verifyModalLabel">Сверка</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="countCalc" class="form-label">Количество (УПП)</label>
              <input
                type="text"
                class="form-control"
                id="countCalc"
                :value="modalData.count_calc"
                disabled
              />
            </div>
            <div class="mb-3">
              <label for="countFact" class="form-label"
                >Количество фактическое</label
              >
              <input
                type="number"
                class="form-control"
                id="countFact"
                v-model="modalData.count_fact"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Отменить
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveModalData"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
import { fetchSkladinvCounts, saveSkladinvString } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { Modal } from "bootstrap";

export default {
  components: {},

  data() {
    return {
      counts: [],
      errorText: "",
      loadingInProgress: false,
      filter: {
        name: "",
        skladCode: "000000004",
        complete: "0",
      },
      modalData: {
        doc_num: null,
        year: null,
        str_num: null,
        count_calc: null,
        count_fact: null,
      },
      verifyModal: null,
    };
  },

  watch: {},

  methods: {
    clearFilter() {
      this.filter.name = "";
      this.loadCounts();
    },

    async loadCounts() {
      this.loadingInProgress = true;

      const fetchResult = await fetchSkladinvCounts(
        this.filter.skladCode,
        this.filter.name,
        this.filter.complete
      );

      this.counts = fetchResult.data;
      this.errorText = fetchResult.errorText;

      for (let i = 0; i < this.counts.length; i++) {
        this.counts[i].index = i + 1;
      }

      this.loadingInProgress = false;
    },

    async saveString(doc_num, year, str_num, count_fact, complete) {
      const fetchResult = await saveSkladinvString(
        doc_num,
        year,
        str_num,
        count_fact,
        complete
      );

      if (fetchResult.bool_result == true) {
        await this.loadCounts();
      } else {
        alert("Ошибка сохранения!");
      }
    },

    openVerifyModal(currentCount) {
      this.modalData = {
        doc_num: currentCount.doc_num,
        year: currentCount.year,
        str_num: currentCount.str_num,
        count_calc: currentCount.count_calc,
        count_fact: currentCount.count_fact,
      };
      this.verifyModal.show();
    },

    async saveModalData() {
      await this.saveString(
        this.modalData.doc_num,
        this.modalData.year,
        this.modalData.str_num,
        this.modalData.count_fact,
        true
      );
      this.verifyModal.hide();
    },

    async returnToUnverified(currentCount) {
      if (confirm("Действительно вернуть?")) {
        await this.saveString(
          currentCount.doc_num,
          currentCount.year,
          currentCount.str_num,
          currentCount.count_calc,
          false
        );
      }
    },
  },

  mounted() {
    this.loadCounts();
    this.verifyModal = new Modal(document.getElementById("verifyModal"));
  },
};
</script>
  
<style scoped>
.table-container {
  overflow-x: auto;
  /* Добавляем горизонтальную прокрутку, если таблица не помещается */
  width: 100%;
  /* Растягиваем контейнер на всю ширину */
}

.s {
  color: red;
}

.table {
  font-size: smaller;
  width: 100%;
  /* Растягиваем таблицу на всю ширину контейнера */
  border-collapse: collapse;
  /* Убираем двойные границы */
}

.table thead {
  background-color: beige;
}

table tr td {
  text-align: left;
  word-wrap: break-word;
  /* Разрешаем перенос слов */
  white-space: normal;
  /* Разрешаем перенос строк */
}

.table-bordered td {
  border: 1px solid darkgray !important;
  /* Упрощаем границы */
}

a {
  outline: none;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 3;
  color: black;
}

.class_green {
  background: lightgreen;
}

.class_green:active {
  background: darkgreen;
}

.class_red {
  background: lightsalmon;
}

.class_red:active {
  background: darksalmon;
}

li:last-child a {
  margin-right: 0;
}

.input_result {
  background-color: yellow;
}

/* Стили для мобильных устройств */
@media (max-width: 500px) {
  .table {
    font-size: 12px;
    /* Уменьшаем размер шрифта для мобильных устройств */
  }

  table tr td {
    padding: 8px;
    /* Уменьшаем отступы для мобильных устройств */
    max-width: 100px;
  }
}
</style>