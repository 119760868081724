const URL_HS_Web = "https://gofroline.akarton.ru/copy/hs/api/";
const URL_HS_Local = "https://192.168.17.20/copy/hs/api/";

const URL_HS_poligraph_Web = "https://gofroline.akarton.ru/poligraph/hs/api/";
const URL_HS_poligraph_Local = "https://192.168.17.20/poligraph/hs/api/";

const URL_HS_Web_Test = "https://gofroline.akarton.ru/upp_copy2/hs/api/";
const URL_HS_Local_Test = "https://192.168.17.20/upp_copy2/hs/api/";

import axios from "axios";
import store from "./store";

// common functions
function getUrlHttpService() {
  return store.state.workInTestBase
    ? store.state.workInLocalNetwork === true
      ? URL_HS_Local_Test
      : URL_HS_Web_Test
    : store.state.workInLocalNetwork === true
    ? URL_HS_Local
    : URL_HS_Web;
}

function getUrlHttpService_poligraph() {
  return store.state.workInLocalNetwork === true ? URL_HS_poligraph_Local : URL_HS_poligraph_Web;
}

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// common http-requests
export async function fetchAccessRights() {
  const url = `${getUrlHttpService()}access_rights?un=${store.state.userName}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchImageTehKarta(num) {
  const url = getUrlHttpService() + `tehkarta_image?num=${num}`;

  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    responseType: "arraybuffer",
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      //console.log('data:image/jpeg;base64,' + _arrayBufferToBase64(response.data).toString('base64'));
      return {
        data: _arrayBufferToBase64(response.data),
        //data: Buffer.from(response.data).toString('base64'),
        //data: response.data,
        //data: new Buffer(response.data, 'binary').toString('base64'),
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchImageTranspKarta(num) {
  const url = getUrlHttpService() + `transpkarta_image?num=${num}`;

  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    responseType: "arraybuffer",
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      //console.log('data:image/jpeg;base64,' + _arrayBufferToBase64(response.data).toString('base64'));
      return {
        data: _arrayBufferToBase64(response.data),
        //data: Buffer.from(response.data).toString('base64'),
        //data: response.data,
        //data: new Buffer(response.data, 'binary').toString('base64'),
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

// gline http-requests
export async function fetchTasksGline(date, complete) {
  const url = `${getUrlHttpService()}list_tasksgline?date=${date}&complete=${complete}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });

  //const response = await axios.get(url, defaultOptions);
  //console.log(response);
  //return response.data;
}

export async function fetchTaskDocGline(num) {
  const url = `${getUrlHttpService()}doc_tasksgline?num=${num}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function saveTaskDocGline(taskNum, task_complete, selected_raport_num, data) {
  const url = `${getUrlHttpService()}doc_tasksgline_save`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    taskNum: taskNum,
    task_complete: task_complete,
    selected_raport_num: selected_raport_num,
    data: data,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then((response) => {
      return {
        bool_result: true,
        errorText: "",
        selected_raport_num: response.data.selected_raport_num,
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
        selected_raport_num: "",
      };
    });
}

// cut http-requests
export async function fetchTasksCut(date, complete, cutn) {
  const url = `${getUrlHttpService()}list_taskscut?date=${date}&complete=${complete}&cutn=${cutn}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchRaportCut(date, brigade_num, cutn) {
  const url = `${getUrlHttpService()}list_raportcut?date=${date}&brigade_num=${brigade_num}&cutn=${cutn}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchTaskDocCut(cutn, taskkey, tasknum) {
  const url = `${getUrlHttpService()}doc_taskscut?cutn=${cutn}&taskkey=${taskkey}&tasknum=${tasknum}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function saveTaskDocCut(cutn, taskkey, tasknum, task_complete, selected_raport_num, data) {
  const url = `${getUrlHttpService()}doc_taskscut_save`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    cutn: cutn,
    taskkey: taskkey,
    tasknum: tasknum,
    task_complete: task_complete,
    selected_raport_num: selected_raport_num,
    data: data,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then((response) => {
      return {
        bool_result: true,
        errorText: "",
        selected_raport_num: response.data.selected_raport_num,
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
        selected_raport_num: "",
      };
    });
}

// other
export async function fetchCustomersWithFilter(filter, top) {
  const url = `${getUrlHttpService()}list_customers?filter=${filter}&top=${top}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function setLimitToCustomer(customer_id, limit) {
  const url = `${getUrlHttpService()}catalog_customers_setlimit`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    customer_id: customer_id,
    limit: limit,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then((response) => {
      return {
        bool_result: true,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchSpecs(customer_id, top) {
  const url = `${getUrlHttpService()}/list_specs?customer_id=${customer_id}&top=${top}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchSpec(spec_id) {
  const url = `${getUrlHttpService()}doc_spec?spec_id=${spec_id}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function setPriceBySpec(spec_id) {
  const url = `${getUrlHttpService()}spec_setprice`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    spec_id: spec_id,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then((response) => {
      return {
        bool_result: true,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchDeliveryList() {
  const url = `${getUrlHttpService_poligraph()}/list_delivery`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function changeStatusDelivery(num) {
  const url = `${getUrlHttpService_poligraph()}delivery_set`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    num: num,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then((response) => {
      return {
        bool_result: true,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchSkladCounts(skladCode, name) {
  const url = `${getUrlHttpService()}sklad_counts?filter=${name}&sklad_code=${skladCode}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function fetchSkladinvCounts(skladCode, name, complete) {
  const url = `${getUrlHttpService()}skladinv?filter=${name}&sklad_code=${skladCode}&complete=${complete}`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  return await axios
    .get(url, defaultOptions)
    .then((response) => {
      return {
        data: response.data,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        data: [],
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}

export async function saveSkladinvString(doc_num, year, str_num, count_fact, complete) {
  const url = `${getUrlHttpService()}saveskladinv`;
  const authorizationString = "Basic " + store.state.token;

  const defaultOptions = {
    headers: {
      Authorization: authorizationString,
      "Content-Type": "text/plain",
    },
  };

  const jsonData = {
    doc_num: doc_num,
    year: year,
    str_num: str_num,
    count_fact: count_fact,
    complete: complete,
  };

  return await axios
    .post(url, jsonData, defaultOptions)
    .then(() => {
      return {
        bool_result: true,
        errorText: "",
      };
    })
    .catch((err) => {
      return {
        bool_result: false,
        errorText: err.response.status === 401 ? "Нет доступа" : err.response.statusText,
      };
    });
}
