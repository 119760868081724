import { createRouter, createWebHashHistory } from "vue-router";
import TasksGlinePage from "../views/TasksGlinePage.vue";
import TaskDocGlinePage from "../views/TaskDocGlinePage.vue";
import TaskDocCutPage from "../views/TaskDocCutPage.vue";
import TasksCutPage from "../views/TasksCutPage.vue";
import RaportCutPage from "../views/RaportCutPage.vue";
import TehkartaViewPage from "../views/TehkartaViewPage.vue";
import TranspkartaViewPage from "../views/TranspkartaViewPage.vue";
import TehkartaCatalogPage from "../views/TehkartaCatalogPage.vue";
import SetLimitPage from "../views/SetLimitPage.vue";
import SpecsListPage from "../views/SpecsListPage.vue";
import DeliveryListPage from "../views/DeliveryListPage.vue";
import SpecPage from "../views/SpecPage.vue";
import LoginPage from "../views/LoginPage.vue";
import HomePage from "../views/HomePage.vue";
import SkladPage from "@/views/SkladPage.vue";
import SkladinvPage from "@/views/SkladinvPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/tasksglinepage",
    name: "tasksglinepage",
    component: TasksGlinePage,
  },
  {
    path: "/taskdocglinepage/:num",
    name: "taskdocglinepage",
    component: TaskDocGlinePage,
  },
  {
    path: "/taskdoccutpage/:cutn/:taskkey/:tasknum",
    name: "taskdoccutpage",
    component: TaskDocCutPage,
  },
  {
    path: "/taskscutpage/:cutn",
    name: "taskscutpage",
    component: TasksCutPage,
  },
  {
    path: "/raportcutpage/:cutn/:y/:m/:d",
    name: "raportcutpage",
    component: RaportCutPage,
  },
  {
    path: "/tehkartaviewpage/:num",
    name: "tehkartaviewpage",
    component: TehkartaViewPage,
  },
  {
    path: "/transpkartaviewpage/:num",
    name: "transpkartaviewpage",
    component: TranspkartaViewPage,
  },
  {
    path: "/tehkartacatalogpage/",
    name: "tehkartacatalogpage",
    component: TehkartaCatalogPage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/setlimit",
    name: "setlimit",
    component: SetLimitPage,
  },
  {
    path: "/specslist",
    name: "specslist",
    component: SpecsListPage,
  },
  {
    path: "/sklad",
    name: "sklad",
    component: SkladPage,
  },
  {
    path: "/skladinv",
    name: "skladinv",
    component: SkladinvPage,
  },
  {
    path: "/deliverylist",
    name: "deliverylist",
    component: DeliveryListPage,
  },
  {
    path: "/spec/:spec_id",
    name: "spec",
    component: SpecPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
