<template>
  <h1>Программа учета производства гофротары "Гофролиния"</h1>
  <h2>(c) ООО "Аполинария", 2023</h2>
  <h2>v.2025.03.24.1</h2>
</template>
     
<script>
export default {};
</script>

<style scoped>
h1 {
  margin-top: 50px;
}
</style>