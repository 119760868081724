<template>
  <div v-if="counts.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td><strong>Наименование</strong></td>
          <td><strong>Группа</strong></td>
          <td><strong>Остаток</strong></td>
        </tr>
      </thead>

      <template v-for="currentCount in counts" :key="currentCount.index">
        <tr>
          <td>{{ currentCount.name }}</td>
          <td>{{ currentCount.group }}</td>
          <td>{{ currentCount.count }}</td>
        </tr>
      </template>
    </table>
  </div>
  <h2 v-else>Нет данных</h2>
</template>

<script>
export default {
  components: {},

  props: {
    counts: {
      type: Array,
    },
  },

  methods: {},
};
</script>

<style scoped>
.table-container {
  overflow-x: auto; /* Добавляем горизонтальную прокрутку, если таблица не помещается */
  width: 100%; /* Растягиваем контейнер на всю ширину */
}

.table {
  font-size: smaller;
  width: 100%; /* Растягиваем таблицу на всю ширину контейнера */
  border-collapse: collapse; /* Убираем двойные границы */
}

.table thead {
  background-color: beige;
}

table tr td {
  text-align: left;
  word-wrap: break-word; /* Разрешаем перенос слов */
  white-space: normal; /* Разрешаем перенос строк */
}

.table-bordered td {
  border: 1px solid darkgray !important; /* Упрощаем границы */
}

/* Стили для мобильных устройств */
@media (max-width: 500px) {
  .table {
    font-size: 12px; /* Уменьшаем размер шрифта для мобильных устройств */
  }

  table tr td {
    padding: 8px; /* Уменьшаем отступы для мобильных устройств */
    max-width: 120px;
  }
}
</style>